import { gql, useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMesagge } from 'components/ErrorMesagge/ErrorMesagge';
import { Textarea } from 'components/Textarea/Textarea';
import { TextInput } from 'components/TextInput/TextInput';
import { notify } from 'components/ToastNotification/ToastNotification';
import React from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { ToastTypes } from 'types/CommonTypes';
import {
    ButtonFormWrapper,
    ErrorMesaggeWrapper,
    FormWrapper,
    Privacy,
    PrivacyInfo,
    StyledButton,
    StyledCheckbox,
} from './Form.styled';
import { validation } from './validation';

const CONTACT_MUTATION = gql`
    mutation ContactForm($input: SendContactFormInput!) {
        sendContactForm(input: $input) {
            clientMutationId
            data
            success
        }
    }
`;

export const Form = () => {
    const {
        control,
        handleSubmit,
        formState: { errors, isDirty, isValid },
        reset,
        watch,
    } = useForm<FieldValues>({
        mode: 'all',
        resolver: yupResolver(validation),
    });
    const form = watch();

    const isError =
        errors.name || errors.phone || errors.email || errors.privacy;

    const [contactMutation] = useMutation(CONTACT_MUTATION, {
        variables: {
            input: {
                emailAddress: form?.email ? form.email : '',
                firstAndLastName: form?.name ? form.name : '',
                messageSubject: form?.subject ? form?.subject : '',
                messageText: form?.message ? form.message : '',
            },
        },
    });

    const onSubmit = () => {
        const contactSubmission = contactMutation();
        contactSubmission.then(() => {
            reset({ name: '', subject: '', email: '', message: '' });
            return notify('Wiadomość została wysłana.')[ToastTypes.SUCCESS]();
        });
        contactSubmission.catch(() => {
            return notify('Wystąpił nieoczkiwany błąd, spróbuj ponownie.')[
                ToastTypes.ERROR
            ]();
        });
    };

    return (
        <FormWrapper onSubmit={handleSubmit(onSubmit)}>
            <TextInput
                control={control}
                errors={errors.name}
                inputIsWhite
                label="Imię i nazwisko *"
                motherPage
                name="name"
                placeholder="Wpisz swoje imię i nazwisko"
                type="text"
            />
            <TextInput
                control={control}
                errors={errors.email}
                inputIsWhite
                label="E-mail *"
                motherPage
                name="email"
                placeholder="Wpisz swój adres e-mail"
                type="text"
            />
            <TextInput
                control={control}
                inputIsWhite
                label="Temat"
                motherPage
                name="subject"
                placeholder="Wpisz temat"
                type="text"
            />
            <Textarea
                control={control}
                label="Wiadomość"
                placeholder={'Wpisz wiadomość'}
                inputIsWhite
                motherPage
                name="message"
            />
            <Privacy>
                <StyledCheckbox
                    name="privacy"
                    control={control}
                    isError={!!errors.privacy}
                />
                <PrivacyInfo as="label" htmlFor="privacy">
                    Wyrażam zgodę na przetwarzanie podanych przeze mnie danych
                    osobowych. Administratorem danych jest Grupa Nieruchomości
                    Kraków Sp. z o.o., posiadająca siedzibę w Krakowie (31-128)
                    przy ulicy Karmelickiej 30/1. Mam świadomość prawa dostępu
                    do swoich danych i ich poprawiania oraz wystąpienia o
                    zaprzestanie przetwarzania podanych przeze mnie danych
                    osobowych. Podanie danych jest dobrowolne.
                </PrivacyInfo>
            </Privacy>
            {errors.privacy && (
                <ErrorMesaggeWrapper>
                    <ErrorMesagge error={errors.privacy} />
                </ErrorMesaggeWrapper>
            )}
            <Privacy>
                <StyledCheckbox
                    name="contact"
                    control={control}
                    isError={!!errors.contact}
                />
                <PrivacyInfo as="label" htmlFor="contact">
                    Wyrażam zgodę na otrzymywanie od Grupy Nieruchomości Kraków
                    Sp. z o.o. za pomocą telefonu i/lub środków komunikacji
                    elektronicznej, skierowanej do mnie informacji handlowej w
                    rozumieniu ustawy z dnia 18 lipca 2002 r. o świadczeniu
                    usług drogą elektroniczną oraz wyrażam zgodę na przesyłanie
                    mi ofert/ogłoszeń nieruchomości i usług przez Grupę
                    Nieruchomości Kraków Sp. z o.o.
                </PrivacyInfo>
            </Privacy>
            {errors.contact && (
                <ErrorMesaggeWrapper>
                    <ErrorMesagge error={errors.contact} />
                </ErrorMesaggeWrapper>
            )}
            <ButtonFormWrapper>
                <StyledButton
                    as="button"
                    disabled={!!isError || !isValid || !isDirty}
                >
                    Napisz do nas
                </StyledButton>
            </ButtonFormWrapper>
        </FormWrapper>
    );
};
