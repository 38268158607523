import { Markdown } from 'components/Markdown/Markdown';
import { mediaQueries } from 'shared/breakpoints';
import styled, { css } from 'styled-components';
import { SectionWrapper, H2 } from 'styles/Typography.styled';

export const DescriptionWrapper = styled.div`
    display: flex;
    gap: 35px;
    align-items: baseline;

    ${mediaQueries.xs} {
        display: grid;
        gap: 24px;
    }
`;

export const StyledTitle = styled(H2)<{ isDark?: boolean }>(
    ({ theme: { colors }, isDark }) => css`
        color: ${isDark ? colors.darkBlue : colors.white};
        margin-bottom: 24px;

        ${mediaQueries.xs} {
            margin-bottom: 0;
        }
    `,
);

export const StyledSectionWrapper = styled(SectionWrapper)(
    ({ theme: { containerWidth } }) => css`
        display: grid;
        grid-gap: 32px;
        padding: 120px 0 64px;
        max-width: ${containerWidth.motherPage};

        ${mediaQueries.md} {
            padding: 56px 16px;
        }

        ${mediaQueries.sm} {
            padding: 48px 20px 54px;
        }

        ${mediaQueries.xs} {
            gap: 24px;
            padding: 48px 20px;
        }
    `,
);

export const DescriptionMarkdown = styled(Markdown)<{ isDark?: boolean }>(
    ({
        theme: { fontSizes, fontWeight, letterSpacing, colors },
        isDark,
    }) => css`
        p {
            color: ${isDark ? colors.motherText : colors.white};
            font-weight: ${fontWeight.regular};
            letter-spacing: ${letterSpacing.m};
            margin: 0 0 18px;
            :last-child {
                margin: 0;
            }

            ${mediaQueries.md} {
                font-size: ${fontSizes.f16};
            }

            ${mediaQueries.sm} {
                line-height: 24px;
            }
        }

        b {
            font-weight: ${fontWeight.semiBold};
        }
        strong {
            font-weight: ${fontWeight.semiBold};
        }
    `,
);
