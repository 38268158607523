import React from 'react';
import {
    AboutUsButton,
    AboutUsDescriptionMarkdown,
    AboutUsMarkdown,
    Container,
    IconWrapper,
    Image,
    InfoBox,
    RightWrapper,
    StyledSectionWrapper,
    StyledTitle,
    ImageWrapper,
} from './AboutUs.styled';
import AboutUsImg from 'images/about-us.png';
import { AboutUsDots } from 'shared/svg/about-us-dots';
import { ImageType, LinkType } from 'types/CommonTypes';

export interface AboutUsProps {
    aboutUsCtaDescription: string;
    aboutUsCtaLink: LinkType;
    aboutUsDescription: string;
    aboutUsFirstImage: ImageType;
    aboutUsSecondImage: ImageType;
    aboutUsTitle: string;
}

export const AboutUs = ({
    aboutUsCtaDescription,
    aboutUsCtaLink,
    aboutUsDescription,
    aboutUsFirstImage,
    aboutUsSecondImage,
    aboutUsTitle,
}: AboutUsProps) => {
    return (
        <Container>
            <StyledSectionWrapper>
                <ImageWrapper>
                    <Image src={aboutUsFirstImage?.sourceUrl} />
                    <Image src={aboutUsSecondImage?.sourceUrl} />
                </ImageWrapper>
                <RightWrapper>
                    <StyledTitle>{aboutUsTitle}</StyledTitle>
                    <AboutUsDescriptionMarkdown markdown={aboutUsDescription} />
                </RightWrapper>
            </StyledSectionWrapper>
            <InfoBox>
                <AboutUsMarkdown markdown={aboutUsCtaDescription} />
                <AboutUsButton
                    target={aboutUsCtaLink?.target}
                    to={aboutUsCtaLink?.url}
                >
                    {aboutUsCtaLink?.title}
                </AboutUsButton>
                <IconWrapper>
                    <AboutUsDots />
                </IconWrapper>
            </InfoBox>
        </Container>
    );
};
