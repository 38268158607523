import React from 'react';

export const HomeCircles = () => {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 408 408"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <mask
                id="mask0_1910_43370"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="408"
                height="408"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M204 141.854C169.678 141.854 141.854 169.678 141.854 204C141.854 238.322 169.678 266.146 204 266.146C238.322 266.146 266.146 238.322 266.146 204C266.146 169.678 238.322 141.854 204 141.854ZM144.556 204C144.556 171.17 171.17 144.556 204 144.556C236.83 144.556 263.444 171.17 263.444 204C263.444 236.83 236.83 263.444 204 263.444C171.17 263.444 144.556 236.83 144.556 204Z"
                    fill="white"
                    fillOpacity="0.5"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M204 131.722C164.082 131.722 131.722 164.082 131.722 204C131.722 243.918 164.082 276.278 204 276.278C243.918 276.278 276.278 243.918 276.278 204C276.278 164.082 243.918 131.722 204 131.722ZM134.424 204C134.424 165.574 165.574 134.424 204 134.424C242.426 134.424 273.576 165.574 273.576 204C273.576 242.426 242.426 273.576 204 273.576C165.574 273.576 134.424 242.426 134.424 204Z"
                    fill="white"
                    fillOpacity="0.5"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M121.589 204C121.589 158.486 158.486 121.589 204 121.589C249.514 121.589 286.411 158.486 286.411 204C286.411 249.514 249.514 286.411 204 286.411C158.486 286.411 121.589 249.514 121.589 204ZM204 124.291C159.978 124.291 124.291 159.978 124.291 204C124.291 248.022 159.978 283.709 204 283.709C248.022 283.709 283.709 248.022 283.709 204C283.709 159.978 248.022 124.291 204 124.291Z"
                    fill="white"
                    fillOpacity="0.5"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M111.457 204C111.457 152.89 152.89 111.457 204 111.457C255.11 111.457 296.543 152.89 296.543 204C296.543 255.11 255.11 296.543 204 296.543C152.89 296.543 111.457 255.11 111.457 204ZM204 114.159C154.382 114.159 114.159 154.382 114.159 204C114.159 253.618 154.382 293.841 204 293.841C253.618 293.841 293.841 253.618 293.841 204C293.841 154.382 253.618 114.159 204 114.159Z"
                    fill="white"
                    fillOpacity="0.5"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M101.325 204C101.325 147.294 147.294 101.325 204 101.325C260.706 101.325 306.676 147.294 306.676 204C306.676 260.706 260.706 306.676 204 306.676C147.294 306.676 101.325 260.706 101.325 204ZM204 104.026C148.786 104.026 104.026 148.786 104.026 204C104.026 259.214 148.786 303.974 204 303.974C259.214 303.974 303.974 259.214 303.974 204C303.974 148.786 259.214 104.026 204 104.026Z"
                    fill="white"
                    fillOpacity="0.5"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M204 91.1921C141.698 91.1921 91.1921 141.698 91.1921 204C91.1921 266.302 141.698 316.808 204 316.808C266.302 316.808 316.808 266.302 316.808 204C316.808 141.698 266.302 91.1921 204 91.1921ZM93.894 204C93.894 143.19 143.19 93.894 204 93.894C264.81 93.894 314.106 143.19 314.106 204C314.106 264.81 264.81 314.106 204 314.106C143.19 314.106 93.894 264.81 93.894 204Z"
                    fill="white"
                    fillOpacity="0.5"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M81.0596 204C81.0596 136.102 136.102 81.0596 204 81.0596C271.898 81.0596 326.94 136.102 326.94 204C326.94 271.898 271.898 326.94 204 326.94C136.102 326.94 81.0596 271.898 81.0596 204ZM204 83.7616C137.594 83.7616 83.7616 137.594 83.7616 204C83.7616 270.406 137.594 324.238 204 324.238C270.406 324.238 324.238 270.406 324.238 204C324.238 137.594 270.406 83.7616 204 83.7616Z"
                    fill="white"
                    fillOpacity="0.5"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M204 70.9272C130.506 70.9272 70.9272 130.506 70.9272 204C70.9272 277.494 130.506 337.073 204 337.073C277.494 337.073 337.073 277.494 337.073 204C337.073 130.506 277.494 70.9272 204 70.9272ZM73.6291 204C73.6291 131.998 131.998 73.6291 204 73.6291C276.002 73.6291 334.371 131.998 334.371 204C334.371 276.002 276.002 334.371 204 334.371C131.998 334.371 73.6291 276.002 73.6291 204Z"
                    fill="white"
                    fillOpacity="0.5"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M60.7947 204C60.7947 124.91 124.91 60.7947 204 60.7947C283.09 60.7947 347.205 124.91 347.205 204C347.205 283.09 283.09 347.205 204 347.205C124.91 347.205 60.7947 283.09 60.7947 204ZM204 63.4967C126.402 63.4967 63.4967 126.402 63.4967 204C63.4967 281.598 126.402 344.503 204 344.503C281.598 344.503 344.503 281.598 344.503 204C344.503 126.402 281.598 63.4967 204 63.4967Z"
                    fill="white"
                    fillOpacity="0.5"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M204 50.6623C119.314 50.6623 50.6623 119.314 50.6623 204C50.6623 288.686 119.314 357.338 204 357.338C288.686 357.338 357.338 288.686 357.338 204C357.338 119.314 288.686 50.6623 204 50.6623ZM53.3642 204C53.3642 120.806 120.806 53.3642 204 53.3642C287.194 53.3642 354.636 120.806 354.636 204C354.636 287.194 287.194 354.636 204 354.636C120.806 354.636 53.3642 287.194 53.3642 204Z"
                    fill="white"
                    fillOpacity="0.5"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M40.5298 204C40.5298 113.718 113.718 40.5298 204 40.5298C294.282 40.5298 367.47 113.718 367.47 204C367.47 294.282 294.282 367.47 204 367.47C113.718 367.47 40.5298 294.282 40.5298 204ZM204 43.2318C115.21 43.2318 43.2318 115.21 43.2318 204C43.2318 292.79 115.21 364.768 204 364.768C292.79 364.768 364.768 292.79 364.768 204C364.768 115.21 292.79 43.2318 204 43.2318Z"
                    fill="white"
                    fillOpacity="0.5"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M204 30.3974C108.122 30.3974 30.3974 108.122 30.3974 204C30.3974 299.878 108.122 377.603 204 377.603C299.878 377.603 377.603 299.878 377.603 204C377.603 108.122 299.878 30.3974 204 30.3974ZM33.0993 204C33.0993 109.614 109.614 33.0993 204 33.0993C298.386 33.0993 374.901 109.614 374.901 204C374.901 298.386 298.386 374.901 204 374.901C109.614 374.901 33.0993 298.386 33.0993 204Z"
                    fill="white"
                    fillOpacity="0.5"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20.2649 204C20.2649 102.526 102.526 20.2649 204 20.2649C305.474 20.2649 387.735 102.526 387.735 204C387.735 305.474 305.474 387.735 204 387.735C102.526 387.735 20.2649 305.474 20.2649 204ZM204 22.9669C104.018 22.9669 22.9669 104.018 22.9669 204C22.9669 303.982 104.018 385.033 204 385.033C303.982 385.033 385.033 303.982 385.033 204C385.033 104.018 303.982 22.9669 204 22.9669Z"
                    fill="white"
                    fillOpacity="0.5"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M204 10.1325C96.9299 10.1325 10.1325 96.9299 10.1325 204C10.1325 311.07 96.9299 397.868 204 397.868C311.07 397.868 397.868 311.07 397.868 204C397.868 96.9299 311.07 10.1325 204 10.1325ZM12.8344 204C12.8344 98.4222 98.4222 12.8344 204 12.8344C309.578 12.8344 395.166 98.4222 395.166 204C395.166 309.578 309.578 395.166 204 395.166C98.4222 395.166 12.8344 309.578 12.8344 204Z"
                    fill="white"
                    fillOpacity="0.5"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0 204C0 91.3339 91.3339 0 204 0C316.666 0 408 91.3339 408 204C408 316.666 316.666 408 204 408C91.3339 408 0 316.666 0 204ZM204 2.70199C92.8262 2.70199 2.70199 92.8262 2.70199 204C2.70199 315.174 92.8262 405.298 204 405.298C315.174 405.298 405.298 315.174 405.298 204C405.298 92.8262 315.174 2.70199 204 2.70199Z"
                    fill="white"
                    fillOpacity="0.5"
                />
            </mask>
            <g mask="url(#mask0_1910_43370)">
                <rect x="212" y="-39" width="238" height="249" fill="#CDEEFE" />
            </g>
        </svg>
    );
};
