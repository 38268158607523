import React, { useState } from 'react';
import { Navigation } from 'swiper';
import { SwiperSlide } from 'swiper/react';
import {
    StyledSectionWrapper,
    SwiperWrapper,
    HeaderSectionWrapper,
    ArrowsWrapper,
    MobileListWrapper,
    LoadMoredButton,
} from './Realizations.styled';
import {
    MotherWebsiteSliderArrow,
    MotherWebsiteSliderArrowHovered,
} from 'shared/svg/arrows';
import { RealizationCard } from '../RealizationCard/RealizationCard';
import { useBreakpoint } from 'utils/hooks/useBreakpoints';
import { RealizationType } from 'types/CommonTypes';
import { SectionDescription } from '../_components/SectionDescription/SectionDescription';

export interface RealizationsProps {
    realizations: RealizationType[];
    realizationsDescription: string;
    realizationsTitle: string;
}

export const Realizations = ({
    realizations,
    realizationsDescription,
    realizationsTitle,
}: RealizationsProps) => {
    const isMobile = useBreakpoint('sm');

    const [isPrevBtnHovered, setIsPrevBtnHovered] = useState(false);
    const [isNextBtnHovered, setIsNextBtnHovered] = useState(false);
    const [numberOfRows, setNumberOfRows] = useState(4);

    const loadMore = () => {
        setNumberOfRows((prevState) => prevState + 4);
    };

    const cutRealizations = realizations.slice(0, numberOfRows);

    return (
        <StyledSectionWrapper>
            <HeaderSectionWrapper>
                <SectionDescription
                    title={realizationsTitle}
                    description={realizationsDescription}
                    isDark
                />
                {!isMobile && realizations.length > 4 && (
                    <ArrowsWrapper>
                        <div
                            className="swiper-button-prev swiper-button"
                            onMouseOver={() =>
                                setIsPrevBtnHovered(!isPrevBtnHovered)
                            }
                            onMouseOut={() =>
                                setIsPrevBtnHovered(!isPrevBtnHovered)
                            }
                        >
                            {isPrevBtnHovered ? (
                                <MotherWebsiteSliderArrowHovered />
                            ) : (
                                <MotherWebsiteSliderArrow />
                            )}
                        </div>
                        <div
                            className="swiper-button-next swiper-button"
                            onMouseOver={() =>
                                setIsNextBtnHovered(!isNextBtnHovered)
                            }
                            onMouseOut={() =>
                                setIsNextBtnHovered(!isNextBtnHovered)
                            }
                        >
                            {isNextBtnHovered ? (
                                <MotherWebsiteSliderArrowHovered />
                            ) : (
                                <MotherWebsiteSliderArrow />
                            )}
                        </div>
                    </ArrowsWrapper>
                )}
            </HeaderSectionWrapper>
            {!isMobile && (
                <SwiperWrapper
                    modules={[Navigation]}
                    slidesPerView={4}
                    spaceBetween={35}
                    navigation={{
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    }}
                    className="mySwiperUpper"
                >
                    {realizations.map((realization, index) => (
                        <SwiperSlide key={index}>
                            <RealizationCard realization={realization} />
                        </SwiperSlide>
                    ))}
                </SwiperWrapper>
            )}

            {isMobile && (
                <MobileListWrapper>
                    {' '}
                    {cutRealizations.map((realization, index) => {
                        return (
                            <RealizationCard
                                realization={realization}
                                key={index}
                            />
                        );
                    })}
                    {realizations.length > numberOfRows && (
                        <LoadMoredButton onClick={loadMore}>
                            Pokaż wszystkie
                        </LoadMoredButton>
                    )}
                </MobileListWrapper>
            )}
        </StyledSectionWrapper>
    );
};
