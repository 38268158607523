import Head from 'components/Head';
import { ToastNotification } from 'components/ToastNotification/ToastNotification';
import React from 'react';
import { Element } from 'react-scroll';
import { AboutUs } from 'src/mother-website/AboutUs/AboutUs';
import { Contact } from 'src/mother-website/Contact/Contact';
import { Footer } from 'src/mother-website/Footer/Footer';
import { Header } from 'src/mother-website/Header/Header';
import { InvestmentMap } from 'src/mother-website/InvestmentMap/InvestmentMap';
import styled, { ThemeProvider } from 'styled-components';
import GlobalStyles from 'styles/GlobalStyles.styled';
import { Theme } from 'types/CommonTypes';
import themeVariant from 'utils/themeVariant';
import { Hero } from 'src/mother-website/Hero/Hero';
import { Investments } from 'src/mother-website/Investments/Investments';
import { useBreakpoint } from 'utils/hooks/useBreakpoints';
import { InvestmentsMobile } from 'src/mother-website/InvestmentsMobile/InvestmentsMobile';
import { graphql } from 'gatsby';
import { Realizations } from 'src/mother-website/Realizations/Realizations';
import { PlannedInvestments } from 'src/mother-website/PlannedInvestments/PlannedInvestments';
import { BuyLand } from 'src/mother-website/BuyLand/BuyLand';

interface MotherWebsitePageProps {
    location: {
        pathname: string;
    };
    data: any;
}

const MotherWebsiteWrapper = styled.div`
    font-family: 'Inter';
    position: relative;
`;

export default ({ data, location }: MotherWebsitePageProps) => {
    const isMobile = useBreakpoint('xs');
    const motherWebsiteData = data?.wpPage.template.homepage;

    const buyLandDisplay =
        !!motherWebsiteData?.sellParcelDescription &&
        !!motherWebsiteData?.sellParcelCta &&
        !!motherWebsiteData?.sellParcelTitle &&
        !!motherWebsiteData?.sellParcelShortDesc &&
        !!motherWebsiteData?.sellParcelImage;

    return (
        <MotherWebsiteWrapper>
            <iframe
                src="https://www.googletagmanager.com/ns.html?id=GTM-5Q62TK9"
                height="0"
                width="0"
                style={{ display: 'none', visibility: 'hidden' }}
            ></iframe>
            <GlobalStyles isStickyMenu={true} />
            <Head pathname={location.pathname} />
            <ThemeProvider theme={themeVariant[Theme.DEFAULT]}>
                <Header
                    buyLandDisplay={buyLandDisplay}
                    plannedInvestmentDisplay={
                        motherWebsiteData?.plannedInvestmentsTitle &&
                        motherWebsiteData?.plannedInvestmentsDescription &&
                        motherWebsiteData?.plannedInvestments
                    }
                />

                <Element name="hero">
                    <Hero
                        title={motherWebsiteData?.headerTitle}
                        image={motherWebsiteData?.headerImage}
                        description={motherWebsiteData?.headerDescription}
                        isRounded={motherWebsiteData?.activeInvestments?.length}
                    />
                </Element>
                {motherWebsiteData?.activeInvestments?.length && (
                    <Element name="investments">
                        {isMobile ? (
                            <InvestmentsMobile
                                title={
                                    motherWebsiteData?.activeInvestmentsTitle
                                }
                                description={
                                    motherWebsiteData?.activeInvestmentsDescription
                                }
                                investments={
                                    motherWebsiteData?.activeInvestments
                                }
                            />
                        ) : (
                            <Investments
                                title={
                                    motherWebsiteData?.activeInvestmentsTitle
                                }
                                description={
                                    motherWebsiteData?.activeInvestmentsDescription
                                }
                                investments={
                                    motherWebsiteData?.activeInvestments
                                }
                            />
                        )}
                    </Element>
                )}
                {motherWebsiteData?.plannedInvestmentsTitle &&
                    motherWebsiteData?.plannedInvestmentsDescription &&
                    motherWebsiteData?.plannedInvestments && (
                        <Element name="investmentsPlanned">
                            <PlannedInvestments
                                title={
                                    motherWebsiteData?.plannedInvestmentsTitle
                                }
                                description={
                                    motherWebsiteData?.plannedInvestmentsDescription
                                }
                                investment={
                                    motherWebsiteData?.plannedInvestments[0]
                                }
                            />
                        </Element>
                    )}
                <Element name="realizations">
                    <Realizations
                        realizations={motherWebsiteData?.realizations}
                        realizationsDescription={
                            motherWebsiteData?.realizationsDescription
                        }
                        realizationsTitle={motherWebsiteData?.realizationsTitle}
                    />
                </Element>
                <Element name="investmentMap">
                    <InvestmentMap
                        investmentMapDescription={
                            motherWebsiteData?.investmentMapDescription
                        }
                        investmentMapTitle={
                            motherWebsiteData?.investmentMapTitle
                        }
                        investments={motherWebsiteData?.activeInvestments}
                        realizations={motherWebsiteData?.realizations}
                    />
                </Element>
                <Element name="aboutUs">
                    <AboutUs
                        aboutUsCtaDescription={
                            motherWebsiteData?.aboutUsCtaDescription
                        }
                        aboutUsCtaLink={motherWebsiteData?.aboutUsCtaLink}
                        aboutUsDescription={
                            motherWebsiteData?.aboutUsDescription
                        }
                        aboutUsFirstImage={motherWebsiteData?.aboutUsFirstImage}
                        aboutUsSecondImage={
                            motherWebsiteData?.aboutUsSecondImage
                        }
                        aboutUsTitle={motherWebsiteData?.aboutUsTitle}
                    />
                </Element>
                {buyLandDisplay && (
                    <Element name="buyLand">
                        <BuyLand
                            contentText={
                                motherWebsiteData?.sellParcelDescription
                            }
                            buttonText={motherWebsiteData?.sellParcelCta}
                            title={motherWebsiteData?.sellParcelTitle}
                            description={motherWebsiteData?.sellParcelShortDesc}
                            image={motherWebsiteData?.sellParcelImage}
                        />
                    </Element>
                )}
                <Element name="contact">
                    <Contact
                        contactDescription={
                            motherWebsiteData?.contactDescription
                        }
                        contactPhones={motherWebsiteData?.contactPhoneNumbers}
                        contactTitle={motherWebsiteData?.contactTitle}
                        emailAddresses={
                            motherWebsiteData?.contactEmailAddresses
                        }
                        mapPinLat={motherWebsiteData?.contactMapPinLat}
                        mapPinLng={motherWebsiteData?.contactMapPinLng}
                        salesOfficeAddress={motherWebsiteData?.contactAddress}
                    />
                </Element>

                <Footer />
                <ToastNotification />
            </ThemeProvider>
        </MotherWebsiteWrapper>
    );
};

export const MotherWebsite = graphql`
    query ParasolHome {
        wpPage(isFrontPage: { eq: true }, status: { eq: "publish" }) {
            id
            template {
                ... on WpTemplate_StronaGlowna {
                    templateName
                    homepage {
                        headerTitle
                        headerDescription
                        headerImage {
                            ...ImageFragment
                        }
                        activeInvestmentsTitle
                        activeInvestmentsDescription
                        activeInvestments {
                            investmentImage {
                                ...ImageFragment
                            }
                            investmentType
                            externalInvestmentUrl
                            investmentDetails {
                                ... on WpInvestment {
                                    id
                                    title
                                    slug
                                }
                            }
                            mapPinLat
                            mapPinLng
                        }
                        plannedInvestmentsTitle
                        plannedInvestmentsDescription
                        plannedInvestments {
                            investmentImage {
                                ...ImageFragment
                            }
                            investmentDetails {
                                ... on WpInvestment {
                                    id
                                    title
                                    slug
                                }
                            }
                            mapPinLat
                            mapPinLng
                        }
                        realizationsTitle
                        realizationsDescription
                        realizations {
                            realizationImage {
                                ...ImageFragment
                            }
                            realizationName
                            realizationDescription
                            realizationAddress
                            realizationGallery {
                                type
                                image {
                                    ...ImageFragment
                                }
                                video
                            }
                            mapPinLat
                            mapPinLng
                        }
                        investmentMapTitle
                        investmentMapDescription
                        aboutUsTitle
                        aboutUsDescription
                        aboutUsFirstImage {
                            ...ImageFragment
                        }
                        aboutUsSecondImage {
                            ...ImageFragment
                        }
                        aboutUsCtaDescription
                        aboutUsCtaLink {
                            url
                            title
                            target
                        }
                        contactTitle
                        contactDescription
                        contactMapPinLat
                        contactMapPinLng
                        contactAddress
                        contactEmailAddresses {
                            emailAddress
                        }
                        contactPhoneNumbers {
                            phoneNumber
                        }
                        sellParcelTitle
                        sellParcelShortDesc
                        sellParcelDescription
                        sellParcelCta
                        sellParcelImage {
                            ...ImageFragment
                        }
                    }
                }
            }
        }
    }
    fragment ImageFragment on WpMediaItem {
        sourceUrl
        altText
    }
`;
