import React from 'react';

export const AboutUsDots = () => {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 104 55"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M2.71825 49.5C4.2195 49.5 5.4365 50.7312 5.4365 52.25C5.4365 53.7688 4.2195 55 2.71825 55C1.217 55 -5.29435e-08 53.7688 -1.18253e-07 52.25C-1.83562e-07 50.7312 1.217 49.5 2.71825 49.5Z"
                fill="#013A57"
            />
            <path
                d="M2.71825 33C4.2195 33 5.4365 34.2312 5.4365 35.75C5.4365 37.2688 4.2195 38.5 2.71825 38.5C1.217 38.5 -7.62459e-07 37.2688 -8.27769e-07 35.75C-8.93078e-07 34.2312 1.217 33 2.71825 33Z"
                fill="#013A57"
            />
            <path
                d="M2.71825 16.5C4.2195 16.5 5.4365 17.7312 5.4365 19.25C5.4365 20.7688 4.2195 22 2.71825 22C1.217 22 -1.47198e-06 20.7688 -1.53728e-06 19.25C-1.60259e-06 17.7312 1.217 16.5 2.71825 16.5Z"
                fill="#013A57"
            />
            <path
                d="M2.71825 3.69392e-06C4.21949 3.62721e-06 5.4365 1.23122 5.4365 2.75C5.4365 4.26879 4.21949 5.5 2.71825 5.5C1.217 5.5 -2.18149e-06 4.26879 -2.2468e-06 2.75C-2.31211e-06 1.23122 1.217 3.76062e-06 2.71825 3.69392e-06Z"
                fill="#013A57"
            />
            <path
                d="M19.0278 49.5C20.529 49.5 21.746 50.7312 21.746 52.25C21.746 53.7688 20.529 55 19.0278 55C17.5265 55 16.3095 53.7688 16.3095 52.25C16.3095 50.7312 17.5265 49.5 19.0278 49.5Z"
                fill="#013A57"
            />
            <path
                d="M19.0278 33C20.529 33 21.746 34.2312 21.746 35.75C21.746 37.2688 20.529 38.5 19.0278 38.5C17.5265 38.5 16.3095 37.2688 16.3095 35.75C16.3095 34.2312 17.5265 33 19.0278 33Z"
                fill="#013A57"
            />
            <path
                d="M19.0278 16.5C20.529 16.5 21.746 17.7312 21.746 19.25C21.746 20.7688 20.529 22 19.0278 22C17.5265 22 16.3095 20.7688 16.3095 19.25C16.3095 17.7312 17.5265 16.5 19.0278 16.5Z"
                fill="#013A57"
            />
            <path
                d="M19.0277 2.96923e-06C20.529 2.90252e-06 21.746 1.23122 21.746 2.75C21.746 4.26879 20.529 5.5 19.0277 5.5C17.5265 5.5 16.3095 4.26879 16.3095 2.75C16.3095 1.23122 17.5265 3.03593e-06 19.0277 2.96923e-06Z"
                fill="#013A57"
            />
            <path
                d="M35.3373 49.5C36.8385 49.5 38.0555 50.7312 38.0555 52.25C38.0555 53.7688 36.8385 55 35.3373 55C33.836 55 32.619 53.7688 32.619 52.25C32.619 50.7312 33.836 49.5 35.3373 49.5Z"
                fill="#013A57"
            />
            <path
                d="M35.3373 33C36.8385 33 38.0555 34.2312 38.0555 35.75C38.0555 37.2688 36.8385 38.5 35.3373 38.5C33.836 38.5 32.619 37.2688 32.619 35.75C32.619 34.2312 33.836 33 35.3373 33Z"
                fill="#013A57"
            />
            <path
                d="M35.3373 16.5C36.8385 16.5 38.0555 17.7312 38.0555 19.25C38.0555 20.7688 36.8385 22 35.3373 22C33.836 22 32.619 20.7688 32.619 19.25C32.619 17.7312 33.836 16.5 35.3373 16.5Z"
                fill="#013A57"
            />
            <path
                d="M35.3373 2.24454e-06C36.8385 2.17783e-06 38.0555 1.23122 38.0555 2.75C38.0555 4.26879 36.8385 5.5 35.3373 5.5C33.836 5.5 32.619 4.26879 32.619 2.75C32.619 1.23122 33.836 2.31124e-06 35.3373 2.24454e-06Z"
                fill="#013A57"
            />
            <path
                d="M51.6468 49.5C53.148 49.5 54.365 50.7312 54.365 52.25C54.365 53.7688 53.148 55 51.6468 55C50.1455 55 48.9285 53.7688 48.9285 52.25C48.9285 50.7312 50.1455 49.5 51.6468 49.5Z"
                fill="#013A57"
            />
            <path
                d="M51.6468 33C53.148 33 54.365 34.2312 54.365 35.75C54.365 37.2688 53.148 38.5 51.6468 38.5C50.1455 38.5 48.9285 37.2688 48.9285 35.75C48.9285 34.2312 50.1455 33 51.6468 33Z"
                fill="#013A57"
            />
            <path
                d="M51.6468 16.5C53.148 16.5 54.365 17.7312 54.365 19.25C54.365 20.7688 53.148 22 51.6468 22C50.1455 22 48.9285 20.7688 48.9285 19.25C48.9285 17.7312 50.1455 16.5 51.6468 16.5Z"
                fill="#013A57"
            />
            <path
                d="M51.6468 1.51985e-06C53.148 1.45314e-06 54.365 1.23122 54.365 2.75C54.365 4.26878 53.148 5.5 51.6468 5.5C50.1455 5.5 48.9285 4.26879 48.9285 2.75C48.9285 1.23122 50.1455 1.58655e-06 51.6468 1.51985e-06Z"
                fill="#013A57"
            />
            <path
                d="M67.9563 49.5C69.4575 49.5 70.6745 50.7312 70.6745 52.25C70.6745 53.7688 69.4575 55 67.9563 55C66.455 55 65.238 53.7688 65.238 52.25C65.238 50.7312 66.455 49.5 67.9563 49.5Z"
                fill="#013A57"
            />
            <path
                d="M67.9563 33C69.4575 33 70.6745 34.2312 70.6745 35.75C70.6745 37.2688 69.4575 38.5 67.9563 38.5C66.455 38.5 65.238 37.2688 65.238 35.75C65.238 34.2312 66.455 33 67.9563 33Z"
                fill="#013A57"
            />
            <path
                d="M67.9563 16.5C69.4575 16.5 70.6745 17.7312 70.6745 19.25C70.6745 20.7688 69.4575 22 67.9563 22C66.455 22 65.238 20.7688 65.238 19.25C65.238 17.7312 66.455 16.5 67.9563 16.5Z"
                fill="#013A57"
            />
            <path
                d="M67.9563 7.9516e-07C69.4575 7.28454e-07 70.6745 1.23122 70.6745 2.75C70.6745 4.26878 69.4575 5.5 67.9563 5.5C66.455 5.5 65.238 4.26878 65.238 2.75C65.238 1.23122 66.455 8.61866e-07 67.9563 7.9516e-07Z"
                fill="#013A57"
            />
            <path
                d="M84.2658 49.5C85.767 49.5 86.984 50.7312 86.984 52.25C86.984 53.7688 85.767 55 84.2658 55C82.7645 55 81.5475 53.7688 81.5475 52.25C81.5475 50.7312 82.7645 49.5 84.2658 49.5Z"
                fill="#013A57"
            />
            <path
                d="M84.2658 33C85.767 33 86.984 34.2312 86.984 35.75C86.984 37.2688 85.767 38.5 84.2658 38.5C82.7645 38.5 81.5475 37.2688 81.5475 35.75C81.5475 34.2312 82.7645 33 84.2658 33Z"
                fill="#013A57"
            />
            <path
                d="M84.2658 16.5C85.767 16.5 86.984 17.7312 86.984 19.25C86.984 20.7688 85.767 22 84.2658 22C82.7645 22 81.5475 20.7688 81.5475 19.25C81.5475 17.7312 82.7645 16.5 84.2658 16.5Z"
                fill="#013A57"
            />
            <path
                d="M84.2658 7.0471e-08C85.767 3.76513e-09 86.984 1.23122 86.984 2.75C86.984 4.26878 85.767 5.5 84.2658 5.5C82.7645 5.5 81.5475 4.26878 81.5475 2.75C81.5475 1.23122 82.7645 1.37177e-07 84.2658 7.0471e-08Z"
                fill="#013A57"
            />
            <path
                d="M100.575 49.5C102.077 49.5 103.294 50.7312 103.294 52.25C103.294 53.7688 102.077 55 100.575 55C99.074 55 97.857 53.7688 97.857 52.25C97.857 50.7312 99.074 49.5 100.575 49.5Z"
                fill="#013A57"
            />
            <path
                d="M100.575 33C102.077 33 103.294 34.2312 103.294 35.75C103.294 37.2688 102.077 38.5 100.575 38.5C99.074 38.5 97.857 37.2688 97.857 35.75C97.857 34.2312 99.074 33 100.575 33Z"
                fill="#013A57"
            />
            <path
                d="M100.575 16.5C102.077 16.5 103.294 17.7312 103.294 19.25C103.294 20.7688 102.077 22 100.575 22C99.074 22 97.857 20.7688 97.857 19.25C97.857 17.7312 99.074 16.5 100.575 16.5Z"
                fill="#013A57"
            />
            <path
                d="M100.575 -6.54218e-07C102.077 -7.20924e-07 103.294 1.23122 103.294 2.75C103.294 4.26878 102.077 5.5 100.575 5.5C99.074 5.5 97.857 4.26878 97.857 2.75C97.857 1.23122 99.074 -5.87512e-07 100.575 -6.54218e-07Z"
                fill="#013A57"
            />
        </svg>
    );
};
