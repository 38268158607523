import React, { useMemo } from 'react';
import { BaseMap } from 'components/BaseMap/BaseMap';
import { mapStyles } from 'shared/mapStylesMotherWebsite';
import {
    MapWrapper,
    StyledMarkdown,
    StyledMotherWebsiteTitle,
    StyledSectionWrapper,
} from './InvestmentMap.styled';
import PinDark from 'images/pin-dark.svg';
import PinDarkSmall from 'images/pin-dark-small.svg';
import PinLight from 'images/pin-light.svg';
import PinLightSmall from 'images/pin-light-small.svg';
import { useBreakpoint } from 'utils/hooks/useBreakpoints';
import { RealizationType } from 'types/CommonTypes';

export interface InvestmentMapProps {
    investmentMapDescription: string;
    investmentMapTitle: string;
    investments: {
        investmentDetails: {
            title: string;
            slug: string;
        };
        investmentImage: { altText: string; sourceUrl: string };
        mapPinLat: string;
        mapPinLng: string;
    }[];
    realizations: RealizationType[];
}

export const InvestmentMap = ({
    investmentMapDescription,
    investmentMapTitle,
    investments,
    realizations,
}: InvestmentMapProps) => {
    const isMobile = useBreakpoint('sm');
    const markers = useMemo(() => {
        return [
            ...investments?.map((item: any) => ({
                title: item?.investmentDetails?.title,
                investmentType: item?.investmentType,
                externalInvestmentUrl: item?.externalInvestmentUrl,
                slug: item?.investmentDetails?.slug,
                position: {
                    lat: Number(item?.mapPinLat),
                    lng: Number(item?.mapPinLng),
                },

                iconUrl: isMobile ? PinDarkSmall : PinDark,
            })),
            ...realizations.map((item: any) => ({
                title: item.realizationName,
                position: {
                    lat: Number(item.mapPinLat),
                    lng: Number(item.mapPinLng),
                },

                iconUrl: isMobile ? PinLightSmall : PinLight,
                realization: item,
            })),
        ];
    }, [investments, realizations, isMobile]);

    return (
        <>
            <StyledSectionWrapper>
                <StyledMotherWebsiteTitle>
                    {investmentMapTitle}
                </StyledMotherWebsiteTitle>
                <StyledMarkdown markdown={investmentMapDescription} />
            </StyledSectionWrapper>
            <MapWrapper>
                <BaseMap
                    mapPosition={{
                        lat: 50.06481962988655,
                        lng: 19.939640247172512,
                    }}
                    zoom={12}
                    mapStyles={mapStyles}
                    markers={markers}
                    isMarkerClickable
                />
            </MapWrapper>
        </>
    );
};
