import { mediaQueries } from 'shared/breakpoints';
import styled, { css } from 'styled-components';

export const CardWrapper = styled.div(
    ({ theme: { colors } }) =>
        css`
            max-width: 280px;
            display: flex;
            flex-direction: column;
            &:hover {
                cursor: pointer;
                background: ${colors.darkBlue};
                border-bottom-right-radius: 30px;
                img {
                    filter: opacity(50%);
                }
                p {
                    background-color: ${colors.motherHover};
                }
            }
            ${mediaQueries.sm} {
                max-width: unset;
                width: 100%;
                margin-bottom: 32px;
            }
        `,
);

export const CardImage = styled.img`
    display: block;
    height: 275px;
    margin: 0;
    object-fit: cover;
`;

export const CardTitle = styled.p(
    ({ theme: { colors, fontWeight, letterSpacing, fontSizes } }) =>
        css`
            margin: 0;
            padding: 16px 16px;
            background-color: ${colors.darkBlue};
            color: ${colors.white};
            font-size: ${fontSizes.f16};
            font-weight: ${fontWeight.semiBold};
            letter-spacing: ${letterSpacing.m};
            line-height: 28px;
            border-bottom-right-radius: 30px;
        `,
);
