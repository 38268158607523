import * as yup from 'yup';

export const errorMessages = {
    INVALID_EMAIL_FORMAT:
        'Niepoprawny format adresu e-mail. Prosimy wpisać w formacie name@example.com',
    REQUIRED: 'To pole jest wymagane',
    PRIVACY_TEXT: 'Zaznaczenie zgody jest wymagane',
    WITHOUT_NUMBER: 'Pole ‘Imię i nazwisko’ powinno zawierać tylko litery',
    ONLY_NUMBERS: 'Niepoprawny format telefonu',
};
const letters = /^[aA-zZ\s]+$/;
const numbers = /^\d+$/;
export const emailValidation = yup
    .string()
    .email(errorMessages.INVALID_EMAIL_FORMAT)
    .required(errorMessages.REQUIRED);

export const textValidation = yup
    .string()
    .required(errorMessages.REQUIRED)
    .matches(letters, errorMessages.WITHOUT_NUMBER);

export const privacyValidation = yup
    .boolean()
    .oneOf([true], 'Zaznaczenie zgody jest wymagane')
    .required(errorMessages.PRIVACY_TEXT);

export const validation = yup.object().shape({
    name: textValidation,
    email: emailValidation,
    privacy: privacyValidation,
    contact: privacyValidation,
});
