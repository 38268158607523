import { mediaQueries } from 'shared/breakpoints';
import styled, { css } from 'styled-components';
import { MotherWebsiteTitle, SectionWrapper } from 'styles/Typography.styled';
import { Markdown } from 'components/Markdown/Markdown';

export const StyledSectionWrapper = styled(SectionWrapper)(
    ({ theme: { containerWidth } }) => css`
        display: flex;
        flex-direction: column;

        ${mediaQueries.lg} {
            padding: 0px 20px;
        }

        ${mediaQueries.md} {
            height: 100%;
            max-width: ${containerWidth.motherPage};
            padding: 0 20px;
        }
    `,
);

export const MapWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 630px;
    ${mediaQueries.md} {
        height: 520px;
    }
`;

export const StyledMotherWebsiteTitle = styled(MotherWebsiteTitle)`
    padding-bottom: 24px;
`;

export const StyledMarkdown = styled(Markdown)(
    ({ theme: { fontSizes, fontWeight, letterSpacing, colors } }) => css`
        p {
            color: ${colors.motherText};
            font-size: ${fontSizes.f18};
            font-weight: ${fontWeight.regular};
            letter-spacing: ${letterSpacing.m};
            line-height: 28px;
            padding-bottom: 74px;

            ${mediaQueries.sm} {
                font-size: ${fontSizes.f16};
                line-height: 24px;
                padding-bottom: 32px;
            }

            ${mediaQueries.md} {
                font-size: ${fontSizes.f16};
            }

            ${mediaQueries.sm} {
                line-height: 24px;
            }
        }

        b {
            font-weight: ${fontWeight.semiBold};
        }
        strong {
            font-weight: ${fontWeight.semiBold};
        }
    `,
);
