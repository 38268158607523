import React from 'react';
import {
    Container,
    Image,
    ImageContainer,
    ImageGrid,
    ImageInfo,
    InvestmentName,
    UppercaseTitle,
} from './PlannedInvestments.styled';
import { SectionDescription } from '../_components/SectionDescription/SectionDescription';

interface PlannedInvestmentProps {
    title: string;
    description: string;
    investment: {
        investmentDetails: {
            title: string;
            slug: string;
        };
        investmentImage: { altText: string; sourceUrl: string };
    };
}

export const PlannedInvestments = ({
    title,
    description,
    investment,
}: PlannedInvestmentProps) => {
    return (
        <Container>
            <SectionDescription
                title={title}
                description={description}
                isDark
            />
            <ImageGrid>
                <ImageContainer>
                    <Image
                        src={investment?.investmentImage?.sourceUrl}
                        alt={investment?.investmentImage?.altText}
                    />
                    <ImageInfo>
                        <UppercaseTitle>Inwestycja planowana</UppercaseTitle>
                        <InvestmentName>
                            {investment?.investmentDetails?.title}
                        </InvestmentName>
                    </ImageInfo>
                </ImageContainer>
            </ImageGrid>
        </Container>
    );
};
