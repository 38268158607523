import { ImageType } from 'types/CommonTypes';
import React from 'react';
import {
    Container,
    ContentWrapper,
    LeftWrapper,
    RightWrapper,
    AnchorButton,
    Image,
    ButtonWrapper,
} from './BuyLand.styled';

import { MotherWebsiteText } from 'styles/Typography.styled';
import { SectionDescriptionAlt } from '../_components/SectionDescriptionAlt/SectionDescriptionAlt';
import { useBreakpoint } from 'utils/hooks/useBreakpoints';

export interface BuyLandProps {
    title: string;
    description: string;
    contentText: string;
    buttonText: string;
    image: ImageType;
}

export const BuyLand = ({
    title,
    description,
    buttonText,
    contentText,
    image,
}: BuyLandProps) => {
    const isMobile = useBreakpoint('xs');

    return (
        <Container>
            <SectionDescriptionAlt
                title={title}
                description={description}
                isDark
            />
            <ContentWrapper>
                <LeftWrapper>
                    <MotherWebsiteText>
                        <div
                            dangerouslySetInnerHTML={{ __html: contentText }}
                        />
                    </MotherWebsiteText>
                    <ButtonWrapper>
                        <AnchorButton
                            activeClass={'active'}
                            to={'contact'}
                            spy={true}
                            smooth={true}
                            duration={500}
                        >
                            {buttonText}
                        </AnchorButton>
                    </ButtonWrapper>
                </LeftWrapper>
                {!isMobile && (
                    <RightWrapper>
                        <Image alt={image?.altText} src={image?.sourceUrl} />
                    </RightWrapper>
                )}
            </ContentWrapper>
        </Container>
    );
};
