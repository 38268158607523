import { BaseMap } from 'components/BaseMap/BaseMap';
import LocationIcon from 'images/real-location.svg';
import React from 'react';
import { mapStyles } from 'shared/mapStylesContact';
import { Form } from '../_components/Form/Form';
import {
    Adress,
    AdressBox,
    ContactDescription,
    ContactTitle,
    ContactWrapper,
    FormSectionWrapper,
    Line,
    LinesWrapper,
    MapBox,
    MapWrapper,
    MarkdownText,
    StyledLink,
    StyledTitle,
    Text,
    TextLight,
} from './Contact.styled';

export interface ContactProps {
    contactDescription: string;
    contactPhones: { phoneNumber: string }[];
    contactTitle: string;
    emailAddresses: { emailAddress: string }[];
    mapPinLat: string;
    mapPinLng: string;
    salesOfficeAddress: string;
}

export const Contact = ({
    contactDescription,
    contactPhones,
    contactTitle,
    emailAddresses,
    mapPinLat,
    mapPinLng,
    salesOfficeAddress,
}: ContactProps) => {
    const markers = [
        {
            title: 'Lokalizacja',
            position: {
                lat: Number(mapPinLat),
                lng: Number(mapPinLng),
            },
            iconUrl: LocationIcon,
        },
    ];

    return (
        <ContactWrapper>
            <ContactTitle>{contactTitle}</ContactTitle>
            <ContactDescription markdown={contactDescription} />
            <FormSectionWrapper>
                <Form />
                <MapBox>
                    <MapWrapper>
                        <BaseMap
                            mapPosition={{
                                lat: Number(mapPinLat),
                                lng: Number(mapPinLng),
                            }}
                            zoom={16}
                            markers={markers}
                            mapStyles={mapStyles}
                        />
                    </MapWrapper>
                    <AdressBox>
                        <LinesWrapper>
                            <Line />
                            <Line />
                        </LinesWrapper>
                        <Adress>
                            <StyledTitle>Biuro sprzedaży</StyledTitle>
                            <MarkdownText markdown={salesOfficeAddress} />
                            <Text>
                                <TextLight> e-mail: </TextLight>
                                {emailAddresses?.map((item) => (
                                    <StyledLink
                                        href={'mailto:' + item.emailAddress}
                                    >
                                        {item.emailAddress} <br />
                                    </StyledLink>
                                ))}
                                <TextLight> tel: </TextLight>
                                {contactPhones?.map((item) => (
                                    <StyledLink
                                        href={'tel:' + item.phoneNumber}
                                    >
                                        {item.phoneNumber} <br />
                                    </StyledLink>
                                ))}
                            </Text>
                        </Adress>
                    </AdressBox>
                </MapBox>
            </FormSectionWrapper>
        </ContactWrapper>
    );
};
