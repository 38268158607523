export const mapStyles = [
    {
        featureType: 'all',
        elementType: 'geometry',
        stylers: [
            {
                hue: '#00f8ff',
            },
            {
                lightness: '25',
            },
            {
                saturation: '30',
            },
        ],
    },
    {
        featureType: 'all',
        elementType: 'labels',
        stylers: [
            {
                visibility: 'off',
            },
            {
                color: '#afb4b5',
            },
            {
                weight: '0.01',
            },
        ],
    },
    {
        featureType: 'all',
        elementType: 'labels.text',
        stylers: [
            {
                visibility: 'on',
            },
        ],
    },
    {
        featureType: 'landscape.man_made',
        elementType: 'geometry',
        stylers: [
            {
                saturation: '-80',
            },
            {
                gamma: '1',
            },
        ],
    },
    {
        featureType: 'landscape.man_made',
        elementType: 'geometry.stroke',
        stylers: [
            {
                lightness: '-5',
            },
            {
                saturation: '-10',
            },
        ],
    },
    {
        featureType: 'landscape.natural',
        elementType: 'geometry',
        stylers: [
            {
                saturation: '-80',
            },
            {
                lightness: '0',
            },
        ],
    },
    {
        featureType: 'poi',
        elementType: 'geometry',
        stylers: [
            {
                saturation: '-80',
            },
            {
                lightness: '60',
            },
        ],
    },
    {
        featureType: 'road',
        elementType: 'geometry.fill',
        stylers: [
            {
                color: '#ffffff',
            },
        ],
    },
    {
        featureType: 'road',
        elementType: 'geometry.stroke',
        stylers: [
            {
                color: '#e9f0ef',
            },
        ],
    },
    {
        featureType: 'transit.line',
        elementType: 'all',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'transit.station.airport',
        elementType: 'geometry',
        stylers: [
            {
                saturation: '-80',
            },
            {
                lightness: '80',
            },
        ],
    },
    {
        featureType: 'water',
        elementType: 'geometry',
        stylers: [
            {
                saturation: '-100',
            },
        ],
    },
];
