import React from 'react';
import {
    Container,
    Image,
    ImageContainer,
    ImageGrid,
    ImageInfo,
    InvestmentName,
    UppercaseTitle,
} from './Investments.styled';
import { SectionDescription } from '../_components/SectionDescription/SectionDescription';

interface InvestmentsProps {
    title: string;
    description: string;
    investments: {
        investmentType: string;
        externalInvestmentUrl: string;
        investmentDetails: {
            title: string;
            slug: string;
        };
        investmentImage: { altText: string; sourceUrl: string };
    }[];
}

export const Investments = ({
    title,
    description,
    investments,
}: InvestmentsProps) => {
    return (
        <Container>
            <SectionDescription title={title} description={description} />
            <ImageGrid
                isEven={investments?.length % 2 === 0}
                isOne={investments?.length === 1}
            >
                {investments?.map((item, i) => (
                    <ImageContainer
                        key={i}
                        href={
                            item?.investmentType === 'external'
                                ? item?.externalInvestmentUrl
                                : item?.investmentDetails?.slug
                        }
                        target="_blank"
                    >
                        <Image
                            src={item?.investmentImage?.sourceUrl}
                            alt={item?.investmentImage?.altText}
                        />
                        <ImageInfo>
                            <UppercaseTitle>W sprzedaży</UppercaseTitle>
                            <InvestmentName>
                                {item?.investmentDetails?.title}
                            </InvestmentName>
                        </ImageInfo>
                    </ImageContainer>
                ))}
            </ImageGrid>
        </Container>
    );
};
