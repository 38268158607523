import styled, { css } from 'styled-components';
import { PrimaryButton, Text } from 'styles/Typography.styled';
import { Checkbox } from 'components/Checkbox/Checkbox';

export const FormWrapper = styled.form`
    align-items: center;
    display: grid;
    grid-gap: 24px;
    width: 100%;
`;

export const Privacy = styled.div`
    display: grid;
    grid-template-columns: 24px 1fr;
    gap: 16px;
`;

export const ButtonFormWrapper = styled.div`
    display: flex;
    justify-content: start;
    margin-top: 10px;
    max-width: 300px;
    padding: 0 32px;
    width: 100%;
    z-index: 10;
`;

export const InputWrapper = styled.div`
    display: flex;
    margin-top: 8px;
    margin-bottom: 6px;
`;

export const PhoneWrapper = styled.div`
    width: 100%;
    position: relative;
`;

export const PrivacyInfo = styled(Text)(
    ({ theme: { fontSizes } }) => css`
        font-size: ${fontSizes.f12}px;
        line-height: 20px;
    `,
);

export const StyledButton = styled(PrimaryButton)(
    ({ theme: { colors } }) => css`
        margin: 0 auto;
        width: 100%;
        background: ${colors.darkBlue};
    `,
);

export const ErrorMesaggeWrapper = styled.div`
    margin-left: 30px;
`;

export const StyledCheckbox = styled(Checkbox)(
    ({ theme: { colors } }) => css`
        &:checked {
            background-color: ${colors.darkBlue};
            border: 1px solid ${colors.darkBlue};
        }
    `,
);
