import { Markdown } from 'components/Markdown/Markdown';
import { mediaQueries } from 'shared/breakpoints';
import styled, { css } from 'styled-components';
import { H4, MotherWebsiteTitle } from 'styles/Typography.styled';

export const ContactWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 140px 176px 108px 108px;

    ${mediaQueries.md} {
        padding: 80px 44px;
    }

    ${mediaQueries.sm} {
        padding: 60px 20px;
    }
`;

export const FormSectionWrapper = styled.div`
    display: flex;
    gap: 104px;
    margin-top: 64px;

    ${mediaQueries.sm} {
        flex-direction: column;
        gap: 64px;
    }
`;

export const ContactTitle = styled(MotherWebsiteTitle)`
    margin-bottom: 24px;
`;

export const ContactDescription = styled(Markdown)(
    ({ theme: { colors, fontSizes, fontWeight, letterSpacing } }) =>
        css`
            p {
                color: ${colors.motherText};
                font-size: ${fontSizes.f18};
                font-weight: ${fontWeight.regular};
                line-height: 28px;

                ${mediaQueries.sm} {
                    font-size: ${fontSizes.f16};
                    line-height: 24px;
                    letter-spacing: ${letterSpacing.m};
                }
            }
        `,
);

export const MapBox = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 26px;
    max-width: 420px;

    ${mediaQueries.sm} {
        padding-top: 0;
        max-width: 100%;
    }
`;

export const MapWrapper = styled.div`
    width: 100%;
    height: 300px;
    padding-bottom: 48px;
`;

export const AdressBox = styled.div`
    display: flex;
    gap: 34px;
`;

export const Adress = styled.div`
    flex-direction: column;
    display: flex;
    gap: 24px;

    grid-template-columns: 1fr 1fr;
`;

export const Text = styled.p(
    ({ theme: { colors, fontSizes, fontWeight } }) =>
        css`
            color: ${colors.motherText};
            font-size: ${fontSizes.f16};
            font-weight: ${fontWeight.regular};
            line-height: 24px;
        `,
);

export const MarkdownText = styled(Markdown)(
    ({ theme: { colors, fontSizes, fontWeight } }) =>
        css`
            p {
                color: ${colors.motherText};
                font-size: ${fontSizes.f16};
                font-weight: ${fontWeight.regular};
                line-height: 24px;
            }
        `,
);

export const TextLight = styled.span(
    ({ theme: { colors } }) =>
        css`
            color: ${colors.neutral300};
        `,
);

export const StyledTitle = styled(H4)(
    ({ theme: { colors, fontWeight } }) =>
        css`
            color: ${colors.motherText};
            font-weight: ${fontWeight.bold};
        `,
);

export const StyledLink = styled.a(
    ({ theme: { colors } }) =>
        css`
            color: ${colors.neutral900};

            &:hover {
                color: ${colors.primaryBlue500};
            }
        `,
);

export const LinesWrapper = styled.div`
    display: flex;
    gap: 40px;
    flex-direction: column;
`;

export const Line = styled.div(
    ({ theme: { colors } }) =>
        css`
            border-bottom: 1px solid ${colors.motherText};
            width: 140px;
            height: 60px;

            ${mediaQueries.xs} {
                width: 50px;
            }
        `,
);
