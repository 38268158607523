import React from 'react';
import {
    DescriptionMarkdown,
    DescriptionWrapper,
    StyledSectionWrapper,
    StyledTitle,
} from './SectionDescriptionAlt.styled';
import { useBreakpoint } from 'utils/hooks/useBreakpoints';

export interface SectionDescriptionAltProps {
    title: string;
    description: string;
    isDark?: boolean;
}

export const SectionDescriptionAlt = ({
    title,
    description,
    isDark,
}: SectionDescriptionAltProps) => {
    const isMobile = useBreakpoint('xs');
    return (
        <StyledSectionWrapper>
            <StyledTitle isDark={isDark}>{title}</StyledTitle>
            <DescriptionWrapper>
                {isMobile ? (
                    <svg
                        width="80"
                        height="1"
                        viewBox="0 0 80 1"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <line
                            x1="80"
                            y1="0.5"
                            y2="0.5"
                            stroke={isDark ? '#232F35' : 'white'}
                        />
                    </svg>
                ) : (
                    <svg
                        width="175"
                        height="1"
                        viewBox="0 0 175 1"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <line
                            x1="175"
                            y1="0.5"
                            y2="0.5"
                            stroke={isDark ? '#232F35' : 'white'}
                        />
                    </svg>
                )}
                <DescriptionMarkdown markdown={description} isDark={isDark} />
            </DescriptionWrapper>
        </StyledSectionWrapper>
    );
};
