import React from 'react';
import {
    CircleWrapper,
    Container,
    DotsWrapper,
    HeroMarkdown,
    Image,
    ImageContainer,
    RightWrapper,
    StyledSectionWrapper,
    StyledTitle,
} from './Hero.styled';
import HeroImg from 'images/mother-hero.png';
import { HomeCircles } from 'shared/svg/circle';
import { HomeDots } from 'shared/svg/homeDots';

interface HeroProps {
    title: string;
    description: string;
    image: { altText: string; sourceUrl: string };
    isRounded: boolean;
}

export const Hero = ({ title, image, isRounded, description }: HeroProps) => {
    return (
        <Container isRounded={isRounded}>
            <StyledSectionWrapper>
                <RightWrapper>
                    <StyledTitle>{title}</StyledTitle>
                    <HeroMarkdown markdown={description} />
                </RightWrapper>
                <ImageContainer>
                    <DotsWrapper>
                        <HomeDots />
                    </DotsWrapper>
                    <Image src={image.sourceUrl} alt={image.altText} />
                    <CircleWrapper>
                        <HomeCircles />
                    </CircleWrapper>
                </ImageContainer>
            </StyledSectionWrapper>
        </Container>
    );
};
