import styled, { css } from 'styled-components';

export const Container = styled.section(
    ({ theme: { colors } }) => css`
        position: relative;
        background-color: ${colors.darkBlue};
        width: 100%;

        .swiper-wrapper {
            display: flex;
            gap: 20px;
            padding: 0 10px;
        }

        .swiper-slide {
            width: 200px;
        }

        .pagination-div {
            text-align: center;
            margin-top: 24px;
        }

        .swiper-pagination-bullet {
            background: ${colors.paleBlue};
            opacity: 1;
        }

        .swiper-pagination-bullet-active {
            background: ${colors.motherHover};
            opacity: 1;
        }
    `,
);

export const SlideWrapper = styled.div`
    display: block;
    gap: 6px;
    padding: 0 0 56px 20px;
`;

export const Image = styled.img`
    border-radius: 0px 0px 30px 0px;
    height: 350px;
    object-fit: cover;
    width: 280px;
`;

export const ImageContainer = styled.a`
    cursor: pointer;
    height: 350px;
    position: relative;
`;

export const ImageInfo = styled.div(
    ({ theme: { colors } }) => css`
        background-color: ${colors.aliceBlue};
        border-radius: 0px 0px 30px 0px;
        bottom: 0;
        display: grid;
        left: -10px;
        padding: 20px 26px;
        position: absolute;
        right: -10px;
    `,
);

export const OneInvestmentWrapper = styled.div`
    display: grid;
    padding-bottom: 56px;
    place-items: center;

    ${Image} {
        width: 320px;
    }
`;
