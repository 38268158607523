import React from 'react';

export const HomeDots = () => {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 104 88"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M2.71825 82.5C4.2195 82.5 5.4365 83.7312 5.4365 85.25C5.4365 86.7688 4.2195 88 2.71825 88C1.217 88 -5.29435e-08 86.7688 -1.18253e-07 85.25C-1.83562e-07 83.7312 1.217 82.5 2.71825 82.5Z"
                fill="#CDEEFE"
            />
            <path
                d="M2.71825 66C4.2195 66 5.4365 67.2312 5.4365 68.75C5.4365 70.2688 4.2195 71.5 2.71825 71.5C1.217 71.5 -7.6246e-07 70.2688 -8.27769e-07 68.75C-8.93078e-07 67.2312 1.217 66 2.71825 66Z"
                fill="#CDEEFE"
            />
            <path
                d="M2.71825 49.5C4.2195 49.5 5.4365 50.7312 5.4365 52.25C5.4365 53.7688 4.2195 55 2.71825 55C1.217 55 -1.47198e-06 53.7688 -1.53728e-06 52.25C-1.60259e-06 50.7312 1.217 49.5 2.71825 49.5Z"
                fill="#CDEEFE"
            />
            <path
                d="M2.71825 33C4.21949 33 5.4365 34.2312 5.4365 35.75C5.4365 37.2688 4.21949 38.5 2.71825 38.5C1.217 38.5 -2.18149e-06 37.2688 -2.2468e-06 35.75C-2.31211e-06 34.2312 1.217 33 2.71825 33Z"
                fill="#CDEEFE"
            />
            <path
                d="M2.71825 16.5C4.21949 16.5 5.4365 17.7312 5.4365 19.25C5.4365 20.7688 4.21949 22 2.71825 22C1.217 22 -2.89101e-06 20.7688 -2.95632e-06 19.25C-3.02163e-06 17.7312 1.217 16.5 2.71825 16.5Z"
                fill="#CDEEFE"
            />
            <path
                d="M2.71824 7.50861e-06C4.21949 7.44191e-06 5.4365 1.23122 5.4365 2.75001C5.4365 4.26879 4.21949 5.50001 2.71825 5.50001C1.217 5.50001 -3.60052e-06 4.26879 -3.66583e-06 2.75001C-3.73114e-06 1.23122 1.217 7.57532e-06 2.71824 7.50861e-06Z"
                fill="#CDEEFE"
            />
            <path
                d="M19.0278 82.5C20.529 82.5 21.746 83.7312 21.746 85.25C21.746 86.7688 20.529 88 19.0278 88C17.5265 88 16.3095 86.7688 16.3095 85.25C16.3095 83.7312 17.5265 82.5 19.0278 82.5Z"
                fill="#CDEEFE"
            />
            <path
                d="M19.0278 66C20.529 66 21.746 67.2312 21.746 68.75C21.746 70.2688 20.529 71.5 19.0278 71.5C17.5265 71.5 16.3095 70.2688 16.3095 68.75C16.3095 67.2312 17.5265 66 19.0278 66Z"
                fill="#CDEEFE"
            />
            <path
                d="M19.0278 49.5C20.529 49.5 21.746 50.7312 21.746 52.25C21.746 53.7688 20.529 55 19.0278 55C17.5265 55 16.3095 53.7688 16.3095 52.25C16.3095 50.7312 17.5265 49.5 19.0278 49.5Z"
                fill="#CDEEFE"
            />
            <path
                d="M19.0277 33C20.529 33 21.746 34.2312 21.746 35.75C21.746 37.2688 20.529 38.5 19.0277 38.5C17.5265 38.5 16.3095 37.2688 16.3095 35.75C16.3095 34.2312 17.5265 33 19.0277 33Z"
                fill="#CDEEFE"
            />
            <path
                d="M19.0277 16.5C20.529 16.5 21.746 17.7312 21.746 19.25C21.746 20.7688 20.529 22 19.0277 22C17.5265 22 16.3095 20.7688 16.3095 19.25C16.3095 17.7312 17.5265 16.5 19.0277 16.5Z"
                fill="#CDEEFE"
            />
            <path
                d="M19.0277 6.78392e-06C20.529 6.71722e-06 21.746 1.23122 21.746 2.75001C21.746 4.26879 20.529 5.50001 19.0277 5.50001C17.5265 5.50001 16.3095 4.26879 16.3095 2.75001C16.3095 1.23122 17.5265 6.85063e-06 19.0277 6.78392e-06Z"
                fill="#CDEEFE"
            />
            <path
                d="M35.3373 82.5C36.8385 82.5 38.0555 83.7312 38.0555 85.25C38.0555 86.7688 36.8385 88 35.3373 88C33.836 88 32.619 86.7688 32.619 85.25C32.619 83.7312 33.836 82.5 35.3373 82.5Z"
                fill="#CDEEFE"
            />
            <path
                d="M35.3373 66C36.8385 66 38.0555 67.2312 38.0555 68.75C38.0555 70.2688 36.8385 71.5 35.3373 71.5C33.836 71.5 32.619 70.2688 32.619 68.75C32.619 67.2312 33.836 66 35.3373 66Z"
                fill="#CDEEFE"
            />
            <path
                d="M35.3373 49.5C36.8385 49.5 38.0555 50.7312 38.0555 52.25C38.0555 53.7688 36.8385 55 35.3373 55C33.836 55 32.619 53.7688 32.619 52.25C32.619 50.7312 33.836 49.5 35.3373 49.5Z"
                fill="#CDEEFE"
            />
            <path
                d="M35.3373 33C36.8385 33 38.0555 34.2312 38.0555 35.75C38.0555 37.2688 36.8385 38.5 35.3373 38.5C33.836 38.5 32.619 37.2688 32.619 35.75C32.619 34.2312 33.836 33 35.3373 33Z"
                fill="#CDEEFE"
            />
            <path
                d="M35.3373 16.5C36.8385 16.5 38.0555 17.7312 38.0555 19.25C38.0555 20.7688 36.8385 22 35.3373 22C33.836 22 32.619 20.7688 32.619 19.25C32.619 17.7312 33.836 16.5 35.3373 16.5Z"
                fill="#CDEEFE"
            />
            <path
                d="M35.3372 6.05924e-06C36.8385 5.99253e-06 38.0555 1.23122 38.0555 2.75001C38.0555 4.26879 36.8385 5.50001 35.3373 5.50001C33.836 5.50001 32.619 4.26879 32.619 2.75001C32.619 1.23122 33.836 6.12594e-06 35.3372 6.05924e-06Z"
                fill="#CDEEFE"
            />
            <path
                d="M51.6468 82.5C53.148 82.5 54.365 83.7312 54.365 85.25C54.365 86.7688 53.148 88 51.6468 88C50.1455 88 48.9285 86.7688 48.9285 85.25C48.9285 83.7312 50.1455 82.5 51.6468 82.5Z"
                fill="#CDEEFE"
            />
            <path
                d="M51.6468 66C53.148 66 54.365 67.2312 54.365 68.75C54.365 70.2688 53.148 71.5 51.6468 71.5C50.1455 71.5 48.9285 70.2688 48.9285 68.75C48.9285 67.2312 50.1455 66 51.6468 66Z"
                fill="#CDEEFE"
            />
            <path
                d="M51.6468 49.5C53.148 49.5 54.365 50.7312 54.365 52.25C54.365 53.7688 53.148 55 51.6468 55C50.1455 55 48.9285 53.7688 48.9285 52.25C48.9285 50.7312 50.1455 49.5 51.6468 49.5Z"
                fill="#CDEEFE"
            />
            <path
                d="M51.6468 33C53.148 33 54.365 34.2312 54.365 35.75C54.365 37.2688 53.148 38.5 51.6468 38.5C50.1455 38.5 48.9285 37.2688 48.9285 35.75C48.9285 34.2312 50.1455 33 51.6468 33Z"
                fill="#CDEEFE"
            />
            <path
                d="M51.6468 16.5C53.148 16.5 54.365 17.7312 54.365 19.25C54.365 20.7688 53.148 22 51.6468 22C50.1455 22 48.9285 20.7688 48.9285 19.25C48.9285 17.7312 50.1455 16.5 51.6468 16.5Z"
                fill="#CDEEFE"
            />
            <path
                d="M51.6468 5.33455e-06C53.148 5.26784e-06 54.365 1.23122 54.365 2.75001C54.365 4.26879 53.148 5.50001 51.6468 5.50001C50.1455 5.50001 48.9285 4.26879 48.9285 2.75001C48.9285 1.23122 50.1455 5.40125e-06 51.6468 5.33455e-06Z"
                fill="#CDEEFE"
            />
            <path
                d="M67.9563 82.5C69.4575 82.5 70.6745 83.7312 70.6745 85.25C70.6745 86.7688 69.4575 88 67.9563 88C66.455 88 65.238 86.7688 65.238 85.25C65.238 83.7312 66.455 82.5 67.9563 82.5Z"
                fill="#CDEEFE"
            />
            <path
                d="M67.9563 66C69.4575 66 70.6745 67.2312 70.6745 68.75C70.6745 70.2688 69.4575 71.5 67.9563 71.5C66.455 71.5 65.238 70.2688 65.238 68.75C65.238 67.2312 66.455 66 67.9563 66Z"
                fill="#CDEEFE"
            />
            <path
                d="M67.9563 49.5C69.4575 49.5 70.6745 50.7312 70.6745 52.25C70.6745 53.7688 69.4575 55 67.9563 55C66.455 55 65.238 53.7688 65.238 52.25C65.238 50.7312 66.455 49.5 67.9563 49.5Z"
                fill="#CDEEFE"
            />
            <path
                d="M67.9563 33C69.4575 33 70.6745 34.2312 70.6745 35.75C70.6745 37.2688 69.4575 38.5 67.9563 38.5C66.455 38.5 65.238 37.2688 65.238 35.75C65.238 34.2312 66.455 33 67.9563 33Z"
                fill="#CDEEFE"
            />
            <path
                d="M67.9563 16.5C69.4575 16.5 70.6745 17.7312 70.6745 19.25C70.6745 20.7688 69.4575 22 67.9563 22C66.455 22 65.238 20.7688 65.238 19.25C65.238 17.7312 66.455 16.5 67.9563 16.5Z"
                fill="#CDEEFE"
            />
            <path
                d="M67.9563 4.60986e-06C69.4575 4.54315e-06 70.6745 1.23122 70.6745 2.75C70.6745 4.26879 69.4575 5.5 67.9563 5.5C66.455 5.5 65.238 4.26879 65.238 2.75C65.238 1.23122 66.455 4.67656e-06 67.9563 4.60986e-06Z"
                fill="#CDEEFE"
            />
            <path
                d="M84.2658 82.5C85.767 82.5 86.984 83.7312 86.984 85.25C86.984 86.7688 85.767 88 84.2658 88C82.7645 88 81.5475 86.7688 81.5475 85.25C81.5475 83.7312 82.7645 82.5 84.2658 82.5Z"
                fill="#CDEEFE"
            />
            <path
                d="M84.2658 66C85.767 66 86.984 67.2312 86.984 68.75C86.984 70.2688 85.767 71.5 84.2658 71.5C82.7645 71.5 81.5475 70.2688 81.5475 68.75C81.5475 67.2312 82.7645 66 84.2658 66Z"
                fill="#CDEEFE"
            />
            <path
                d="M84.2658 49.5C85.767 49.5 86.984 50.7312 86.984 52.25C86.984 53.7688 85.767 55 84.2658 55C82.7645 55 81.5475 53.7688 81.5475 52.25C81.5475 50.7312 82.7645 49.5 84.2658 49.5Z"
                fill="#CDEEFE"
            />
            <path
                d="M84.2658 33C85.767 33 86.984 34.2312 86.984 35.75C86.984 37.2688 85.767 38.5 84.2658 38.5C82.7645 38.5 81.5475 37.2688 81.5475 35.75C81.5475 34.2312 82.7645 33 84.2658 33Z"
                fill="#CDEEFE"
            />
            <path
                d="M84.2658 16.5C85.767 16.5 86.984 17.7312 86.984 19.25C86.984 20.7688 85.767 22 84.2658 22C82.7645 22 81.5475 20.7688 81.5475 19.25C81.5475 17.7312 82.7645 16.5 84.2658 16.5Z"
                fill="#CDEEFE"
            />
            <path
                d="M84.2658 3.88517e-06C85.767 3.81846e-06 86.984 1.23122 86.984 2.75C86.984 4.26879 85.767 5.5 84.2658 5.5C82.7645 5.5 81.5475 4.26879 81.5475 2.75C81.5475 1.23122 82.7645 3.95187e-06 84.2658 3.88517e-06Z"
                fill="#CDEEFE"
            />
            <path
                d="M100.575 82.5C102.077 82.5 103.294 83.7312 103.294 85.25C103.294 86.7688 102.077 88 100.575 88C99.074 88 97.857 86.7688 97.857 85.25C97.857 83.7312 99.074 82.5 100.575 82.5Z"
                fill="#CDEEFE"
            />
            <path
                d="M100.575 66C102.077 66 103.294 67.2312 103.294 68.75C103.294 70.2688 102.077 71.5 100.575 71.5C99.074 71.5 97.857 70.2688 97.857 68.75C97.857 67.2312 99.074 66 100.575 66Z"
                fill="#CDEEFE"
            />
            <path
                d="M100.575 49.5C102.077 49.5 103.294 50.7312 103.294 52.25C103.294 53.7688 102.077 55 100.575 55C99.074 55 97.857 53.7688 97.857 52.25C97.857 50.7312 99.074 49.5 100.575 49.5Z"
                fill="#CDEEFE"
            />
            <path
                d="M100.575 33C102.077 33 103.294 34.2312 103.294 35.75C103.294 37.2688 102.077 38.5 100.575 38.5C99.074 38.5 97.857 37.2688 97.857 35.75C97.857 34.2312 99.074 33 100.575 33Z"
                fill="#CDEEFE"
            />
            <path
                d="M100.575 16.5C102.077 16.5 103.293 17.7312 103.293 19.25C103.293 20.7688 102.077 22 100.575 22C99.074 22 97.857 20.7688 97.857 19.25C97.857 17.7312 99.074 16.5 100.575 16.5Z"
                fill="#CDEEFE"
            />
            <path
                d="M100.575 3.16048e-06C102.077 3.09377e-06 103.293 1.23122 103.293 2.75C103.293 4.26879 102.077 5.5 100.575 5.5C99.074 5.5 97.857 4.26879 97.857 2.75C97.857 1.23122 99.074 3.22718e-06 100.575 3.16048e-06Z"
                fill="#CDEEFE"
            />
        </svg>
    );
};
