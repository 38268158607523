import React, { useState } from 'react';

import {
    BurgerButton,
    BurgerMenuContent,
    BurgerMenuWrapper,
    IconLine,
    LinksContainer,
    LinkScrollTo,
} from './MotherWebsiteBurgerMenu.styled';

export interface MotherWebsiteBurgerMenuProps {
    plannedInvestmentDisplay: boolean;
    buyLandDisplay: boolean;
}

export const MotherWebsiteBurgerMenu = ({
    plannedInvestmentDisplay,
    buyLandDisplay,
}: MotherWebsiteBurgerMenuProps) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <BurgerMenuWrapper>
            <BurgerButton
                aria-label="burger-button"
                isOpen={isOpen}
                onClick={() => setIsOpen(!isOpen)}
            >
                <IconLine />
                <IconLine />
                <IconLine />
            </BurgerButton>
            <BurgerMenuContent isOpen={isOpen}>
                <LinksContainer>
                    <LinkScrollTo
                        activeClass="active"
                        to="hero"
                        spy={true}
                        smooth={true}
                        duration={500}
                        offset={-120}
                        onClick={() => setIsOpen(false)}
                    >
                        Realizacje
                    </LinkScrollTo>
                    <LinkScrollTo
                        activeClass="active"
                        to="investments"
                        spy={true}
                        smooth={true}
                        duration={500}
                        offset={-60}
                        onClick={() => setIsOpen(false)}
                    >
                        W sprzedaży
                    </LinkScrollTo>
                    {plannedInvestmentDisplay && (
                        <LinkScrollTo
                            activeClass="active"
                            to="investmentsPlanned"
                            spy={true}
                            smooth={true}
                            duration={500}
                            offset={-60}
                            onClick={() => setIsOpen(false)}
                        >
                            Planowane
                        </LinkScrollTo>
                    )}
                    <LinkScrollTo
                        activeClass="active"
                        to="realizations"
                        spy={true}
                        smooth={true}
                        duration={500}
                        offset={-60}
                        onClick={() => setIsOpen(false)}
                    >
                        Zrealizowane
                    </LinkScrollTo>
                    <LinkScrollTo
                        activeClass="active"
                        to="investmentMap"
                        spy={true}
                        smooth={true}
                        duration={500}
                        offset={-120}
                        onClick={() => setIsOpen(false)}
                    >
                        Mapa
                    </LinkScrollTo>
                    <LinkScrollTo
                        activeClass="active"
                        to="aboutUs"
                        spy={true}
                        smooth={true}
                        duration={500}
                        offset={-60}
                        onClick={() => setIsOpen(false)}
                    >
                        O firmie
                    </LinkScrollTo>
                    {buyLandDisplay && (
                        <LinkScrollTo
                            activeClass="active"
                            to="buyLand"
                            spy={true}
                            smooth={true}
                            duration={500}
                            offset={-60}
                            onClick={() => setIsOpen(false)}
                        >
                            Odkupimy działkę
                        </LinkScrollTo>
                    )}
                    <LinkScrollTo
                        activeClass="active"
                        to="contact"
                        spy={true}
                        smooth={true}
                        duration={500}
                        offset={-60}
                        onClick={() => setIsOpen(false)}
                    >
                        Napisz do nas
                    </LinkScrollTo>
                </LinksContainer>
            </BurgerMenuContent>
        </BurgerMenuWrapper>
    );
};
