import { mediaQueries } from 'shared/breakpoints';
import styled from 'styled-components';
import { css } from 'styled-components';
import { sharedButtonStyles } from 'styles/Typography.styled';
import { Link as AnchorLink } from 'react-scroll';

export const Container = styled.section`
    display: flex;
    flex-direction: column;
    padding: 0 176px 0 108px;

    ${mediaQueries.md} {
        padding: 0 44px;
    }

    ${mediaQueries.sm} {
        padding: 0 20px;
    }
`;

export const ContentWrapper = styled.div`
    display: flex;
    gap: 105px;
`;

export const LeftWrapper = styled.div`
    flex: 1.5;
    display: flex;
    flex-direction: column;
    gap: 40px;
    align-items: flex-start;
`;

export const RightWrapper = styled.div`
    flex: 1;
    display: flex;
`;

export const AnchorButton = styled(AnchorLink)(
    ({ theme: { colors } }) =>
        css`
            ${sharedButtonStyles}
            background: ${colors.darkBlue};
            border: 2px solid transparent;
            color: ${colors.white};
            cursor: pointer;

            &:hover {
                background: ${colors.primaryBlue700};
            }

            ${mediaQueries.xs} {
                width: 100%;
            }
        `,
);

export const Image = styled.img`
    flex: 1;
    max-height: 264px;
    max-width: 100%;
`;

export const ButtonWrapper = styled.div`
    ${mediaQueries.xs} {
        margin: 0 auto;
        padding: 0 32px;
        width: 100%;
    }
`;
