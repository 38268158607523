import { Link } from 'react-scroll';
import { mediaQueries } from 'shared/breakpoints';
import styled, { css } from 'styled-components';

export const FooterWrapper = styled.div(
    ({ theme: { colors } }) =>
        css`
            background-color: ${colors.darkBlue};
            padding: 72px 112px;

            ${mediaQueries.md} {
                padding: 72px 80px;
            }
            ${mediaQueries.sm} {
                padding: 40px 28px;
            }
        `,
);

export const InnerWrapper = styled.div(
    ({ theme: { containerWidth } }) =>
        css`
            align-items: flex-start;
            display: flex;
            margin: 0 auto;
            max-width: ${containerWidth.defalut};

            ${mediaQueries.xs} {
                flex-direction: column;
                gap: 32px;
                width: 300px;
            }
        `,
);

export const InnerWrapperLeft = styled.div`
    display: grid;
    grid-gap: 25px;
    width: 100%;
    max-width: 432px;
`;

export const InnerWrapperRight = styled.div`
    display: flex;
    gap: 50px;
    justify-content: flex-end;
    width: 100%;

    ${mediaQueries.md} {
        flex-wrap: wrap;
        gap: 10px 50px;
        justify-content: flex-start;
        margin-left: 40px;
    }
    ${mediaQueries.sm} {
        flex-direction: column;
        gap: 0px;
        margin-left: 0;
        max-width: 300px;
    }

    ${mediaQueries.xs} {
        gap: 14px;
        max-width: 100%;
    }
`;

export const FooterMenuLink = styled(Link)(
    ({ theme: { colors, letterSpacing, fontWeight } }) =>
        css`
            align-items: center;
            border-bottom: 2px solid transparent;
            color: ${colors.white};
            cursor: pointer;
            display: flex;
            font-weight: ${fontWeight.regular};
            letter-spacing: ${letterSpacing.m};
            line-height: 24px;
            width: fit-content;

            &:hover,
            &:focus {
                color: ${colors.primaryBlue500};
            }

            &[aria-current='page'] {
                border-bottom-color: ${colors.primaryBlue500};
            }
        `,
);

export const FooterLogo = styled.img`
    max-width: 165px;
    object-fit: contain;
`;

export const FooterCopyRightStyle = css(
    ({ theme: { colors, fontSizes, letterSpacing } }) =>
        css`
            color: ${colors.white};
            font-size: ${fontSizes.f12};
            letter-spacing: ${letterSpacing.m};
            line-height: 20px;
        `,
);

export const FooterCopyRightDesktop = styled.div`
    ${FooterCopyRightStyle}
    display: block;
`;
