import React, { useEffect, useState } from 'react';
import {
    Container,
    Image,
    ImageContainer,
    ImageInfo,
    OneInvestmentWrapper,
    SlideWrapper,
} from './InvestmentsMobile.styled';
import { SectionDescription } from '../_components/SectionDescription/SectionDescription';
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useCurrentWidth } from 'utils/hooks/useCurrentWidth';
import {
    InvestmentName,
    UppercaseTitle,
} from '../Investments/Investments.styled';

interface InvestmentsMobileProps {
    title: string;
    description: string;
    investments: {
        investmentType: string;
        externalInvestmentUrl: string;
        investmentDetails: {
            title: string;
            slug: string;
        };
        investmentImage: { altText: string; sourceUrl: string };
    }[];
}

export const InvestmentsMobile = ({
    title = 'Inwestycje w sprzedaży',
    description = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Malesuada a purus id lobortis risus pretium dui aenean. Ultricies faucibus enim lectus in mattis.',
    investments,
}: InvestmentsMobileProps) => {
    const windowWidth = useCurrentWidth();
    const [slidesPerView, setSlidesPerView] = useState(1.2);

    useEffect(() => {
        switch (true) {
            case windowWidth < 375:
                setSlidesPerView(1);
                break;
            case windowWidth >= 425 && windowWidth < 486:
                setSlidesPerView(1.3);
                break;
            case windowWidth >= 486:
                setSlidesPerView(1.6);
                break;
            default:
                setSlidesPerView(1.2);
        }
    }, [windowWidth]);

    return (
        <Container>
            <SectionDescription title={title} description={description} />
            {investments?.length === 1 ? (
                <OneInvestmentWrapper>
                    <ImageContainer
                        href={
                            investments[0]?.investmentType === 'external'
                                ? investments[0]?.externalInvestmentUrl
                                : investments[0]?.investmentDetails?.slug
                        }
                        target="_blank"
                    >
                        <Image
                            src={investments[0]?.investmentImage?.sourceUrl}
                            alt={investments[0]?.investmentImage?.altText}
                        />
                        <ImageInfo>
                            <UppercaseTitle>W sprzedaży</UppercaseTitle>
                            <InvestmentName>
                                {investments[0]?.investmentDetails?.title}
                            </InvestmentName>
                        </ImageInfo>
                    </ImageContainer>
                </OneInvestmentWrapper>
            ) : (
                <SlideWrapper>
                    <Swiper
                        slidesPerView={slidesPerView}
                        pagination={{
                            el: '.pagination-div',
                            clickable: true,
                            renderBullet: (index, className) => {
                                return (
                                    '<span class="' +
                                    className +
                                    '">' +
                                    '</span>'
                                );
                            },
                        }}
                        modules={[Pagination]}
                        className="mySwiper"
                    >
                        {investments?.map((item, i) => (
                            <SwiperSlide key={i}>
                                <ImageContainer
                                    key={i}
                                    href={
                                        item?.investmentType === 'external'
                                            ? item?.externalInvestmentUrl
                                            : item?.investmentDetails?.slug
                                    }
                                    target="_blank"
                                >
                                    <Image
                                        src={item?.investmentImage?.sourceUrl}
                                        alt={item?.investmentImage?.altText}
                                    />
                                    <ImageInfo>
                                        <UppercaseTitle>
                                            W sprzedaży
                                        </UppercaseTitle>
                                        <InvestmentName>
                                            {item?.investmentDetails?.title}
                                        </InvestmentName>
                                    </ImageInfo>
                                </ImageContainer>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                    <div className="pagination-div" />
                </SlideWrapper>
            )}
        </Container>
    );
};
