import Logo from 'images/real-logo.svg';
import React from 'react';
import { useBreakpoint } from 'utils/hooks/useBreakpoints';
import {
    FooterCopyRightDesktop,
    FooterLogo,
    FooterMenuLink,
    FooterWrapper,
    InnerWrapper,
    InnerWrapperLeft,
    InnerWrapperRight,
} from './Footer.styled';

export const Footer = () => {
    const isMobile = useBreakpoint('xs');
    return (
        <FooterWrapper>
            <InnerWrapper>
                <InnerWrapperLeft>
                    <FooterLogo alt="" src={Logo} />
                    {!isMobile && (
                        <FooterCopyRightDesktop>
                            Copyrights © 2022 Real Construct
                        </FooterCopyRightDesktop>
                    )}
                </InnerWrapperLeft>
                <InnerWrapperRight>
                    <FooterMenuLink
                        activeClass="active"
                        to="investments"
                        spy={true}
                        smooth={true}
                        duration={500}
                    >
                        W sprzedaży
                    </FooterMenuLink>
                    <FooterMenuLink
                        activeClass="active"
                        to="investmentsPlanned"
                        spy={true}
                        smooth={true}
                        duration={500}
                    >
                        Planowane
                    </FooterMenuLink>
                    <FooterMenuLink
                        activeClass="active"
                        to="realizations"
                        spy={true}
                        smooth={true}
                        duration={500}
                    >
                        Zrealizowane
                    </FooterMenuLink>
                    <FooterMenuLink
                        activeClass="active"
                        to="investmentMap"
                        spy={true}
                        smooth={true}
                        duration={500}
                    >
                        Mapa
                    </FooterMenuLink>
                    <FooterMenuLink
                        activeClass="active"
                        to="aboutUs"
                        spy={true}
                        smooth={true}
                        duration={500}
                    >
                        O firmie
                    </FooterMenuLink>
                    <FooterMenuLink
                        activeClass="active"
                        to="buyLand"
                        spy={true}
                        smooth={true}
                        duration={500}
                    >
                        Odkupimy działkę
                    </FooterMenuLink>
                </InnerWrapperRight>
                {isMobile && (
                    <FooterCopyRightDesktop>
                        Copyrights © 2022 Real Construct
                    </FooterCopyRightDesktop>
                )}
            </InnerWrapper>
        </FooterWrapper>
    );
};
