import React, { useState } from 'react';
import { RealizationType } from 'types/CommonTypes';
import { useBreakpoint } from 'utils/hooks/useBreakpoints';
import { RealizationModal } from '../RealizationModal/RealizationModal';
import { RealizationModalMobile } from '../RealizationModalMobile/RealizationModal';
import { CardImage, CardTitle, CardWrapper } from './RealizationCard.styled';

export interface RealizationCardProps {
    realization: RealizationType;
}

export const RealizationCard = ({ realization }: RealizationCardProps) => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const isMobile = useBreakpoint('md');
    return (
        <>
            <CardWrapper onClick={() => setModalIsOpen(true)}>
                <CardImage src={realization.realizationImage.sourceUrl} />
                <CardTitle>{realization.realizationName}</CardTitle>
            </CardWrapper>

            {isMobile ? (
                <RealizationModalMobile
                    modalIsOpen={modalIsOpen}
                    setModalIsOpen={setModalIsOpen}
                    realization={realization}
                />
            ) : (
                <RealizationModal
                    modalIsOpen={modalIsOpen}
                    setModalIsOpen={setModalIsOpen}
                    realization={realization}
                />
            )}
        </>
    );
};
